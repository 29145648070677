import React, { useState, useLayoutEffect, useRef } from "react"

import Home from "../Home"
import About from "../About"
import Book from "../Book"
// import Tools from "../Tools"
import Foundations from "../Foundations"
import Businesses from "../Businesses"
import Contact from "../Contact"
import VoiceOfFathers from "../VoiceOfFathers"
import Layout from "../Layout"

const ScrollContainer = () => {
  const refBook = useRef()
  const refFoundation = useRef()
  const refBusinesses = useRef()
  const refAbout = useRef()
  const refContact = useRef()

  const [posBook, setPosBook] = useState(0)
  // const [posTools, setPosTools] = useState(0)
  const [posFoundation, setPosFoundation] = useState(0)
  const [posBusinesses, setPosBusinesses] = useState(0)
  const [posAbout, setPosAbout] = useState(0)
  const [posContact, setContact] = useState(0)

  const posOjb = {
    posBook,
    // posTools,
    posFoundation,
    posBusinesses,
    posAbout,
    posContact,
  }

  useLayoutEffect(() => {
    function updatePosition() {
      setPosBook(refBook.current.offsetTop)
      // setPosTools(refTools.current.offsetTop)
      setPosFoundation(refFoundation.current.offsetTop)
      setPosBusinesses(refBusinesses.current.offsetTop)
      setPosAbout(refAbout.current.offsetTop)
      setContact(refContact.current.offsetTop)
    }
    window.addEventListener("resize", updatePosition)
    updatePosition()
    return () => window.removeEventListener("resize", updatePosition)
  }, [])

  return (
    <Layout {...posOjb}>
      <Home />
      <Book refPos={refBook} />
      <VoiceOfFathers />
      {/* <Tools refPos={refTools}/> */}
      <Foundations refPos={refFoundation} />
      <Businesses refPos={refBusinesses} />
      <About refPos={refAbout} />
      <Contact refPos={refContact} />
    </Layout>
  )
}

export default ScrollContainer
