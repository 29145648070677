import React from "react"
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@material-ui/core"

import Section from "../Section"
import Divider from "../Divider"

const Contact = props => {
  return (
    <Box >
      <Section variant="white">
        <Divider tc="#76AEA1" bc="#fff" />
        <Box id="contact" py={10} >
          <Container ref={props.refPos}>
            <Typography variant="h2" align="center" color="primary">
              Contact
            </Typography>
            <Typography variant="h4" align="center" color="textSecondary">
              Send Dr. Michael J your inquiry
            </Typography>
            <Box my={10} display="flex" justifyContent="center">
              <form
                name="contact"
                method="post"
                action="/thanks"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />

                <Box display="flex">
                  <Box m={2}>
                    <TextField
                      required
                      id="first-name"
                      name="first-name"
                      label="First Name"
                      variant="filled"
                    />
                  </Box>
                  <Box m={2}>
                    <TextField
                      required
                      id="last-name"
                      name="last-name"
                      label="Last Name"
                      variant="filled"
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box m={2}>
                    <TextField
                      required
                      id="phone"
                      name="phone"
                      label="Phone"
                      variant="filled"
                    />
                  </Box>
                  <Box m={2}>
                    <TextField
                      required
                      id="email"
                      name="email"
                      label="Email"
                      variant="filled"
                    />
                  </Box>
                </Box>

                <Box display="flex" justifyContent="center">
                  <Button type="submit" variant="contained" secondary required size="large">
                    Send your message
                  </Button>
                </Box>
              </form>
              
            </Box>
          </Container>
        </Box>
      </Section>
    </Box>
  )
}

Contact.propTypes = {}

export default Contact
