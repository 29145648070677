import { rem } from "polished"

import { Box, styled } from "@material-ui/core"

const StyledFMDLogo = styled(Box)(({ theme }) => ({
  background: `rgba(255,255,255, 0.1)`,
  borderRadius: "50%",
  height: `${rem(500)}`,
  width: `${rem(500)}`,
}))

export { StyledFMDLogo }
