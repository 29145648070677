import React from "react"
import { Grid, Box, Container, Typography } from "@material-ui/core"

import Section from "../Section"
import Divider from "../Divider"

import ImgBook from "../../images/author-site-photo.jpg"

const Home = () => {
  return (
    <Box id="voiceoffathers">
      <Section variant="primary">
        <Divider tc="#fff" bc="#002749" />
        <Box py={10}>
          <Container>
            <Grid container>
              <Grid item xs={12} md={6}>
                <img src={ImgBook} alt="book" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box py={1}>
                  <Container>
                    <Typography variant="h2" align="left" color="white">
                      Forever My Daddy
                    </Typography>
                    <Typography variant="h4" align="left" color="secondary">
                      Voice of Fathers
                    </Typography>
                    <Typography align="left" paragraph color="textSecondary">
                      Dr. Michael J. shares the concept of Forever My Daddy
                      (FMD) that emerged over twenty years ago. FMD is the voice
                      for fathers who are unfairly pushed away from their
                      children, and blocked from developing a deep and true
                      relationship, whose desire to be a loving presence for
                      their children is thwarted by subtle actions of the prior
                      spouses and the biases of court systems that actually
                      serve to increase fatherlessness. He shares ideas and
                      lessons in a fascinating way that could assist parents,
                      children, teachers, girlfriends, spouses and others on
                      ways to avoid parental alienation while highlighting
                      co-parenting.
                    </Typography>
                  </Container>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Section>
    </Box>
  )
}

Home.propTypes = {}

export default Home
