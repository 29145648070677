import React from "react"
import { Container, Box, Typography } from "@material-ui/core"

import Section from "../Section"

const Footer = props => {
  return (
    <Section variant="primary">
      <Container maxWidth="lg">
        <Box p={5} display="flex" justifyContent="center">
          <Typography color="textSecondary">{`©${new Date().getFullYear()} Michael S. Joyner. All Rights Reserved.`}</Typography>
        </Box>
      </Container>
    </Section>
  )
}

Footer.propTypes = {}

export default Footer
