import React from "react"
import { Box, Button,Container, Grid, Typography } from "@material-ui/core"

import { StyledMJGlobalLogo } from "./Businesses.styled"

import Section from "../Section"
import Divider from "../Divider"

import ImgLogoMJGlobal from "../../images/Global_SVG_white.svg"

const Businesses = (props) => {
  return (
    <Box >
      <Section variant="primary">
        <Divider tc="#fff" bc="#002749" />
        <Box id="businesses" py={10} ref={props.refPos}>
          <Container>
            <Grid  container>
              <Grid item xs={12} md={6}>
                <Button href="https://www.mjmdglobal.com/">
                <StyledMJGlobalLogo
                  component="img"
                  src={ImgLogoMJGlobal}
                  alt="joyner holding sign"
                />
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h2" align="left" color="white">
                  Businesses
                </Typography>
                <Typography variant="h4" align="left" color="secondary">
                  Making "New History"
                </Typography>
                <Typography align="left" paragraph color="textSecondary">
                  Dr. Michael J is a true inventor who is passionate about hand
                  surgery. His work revolves around his ultimate goal, which is
                  to make “New History” with his businesses. An exemplification
                  of this new history is his Touchless Flatware, Chopsticks and
                  Dishware.
                </Typography>
                <Typography align="left" paragraph color="textSecondary">
                  His powerful portfolio does not stop there; he also is the
                  founder of many companies, all of which have strong financial
                  stability. His companies span from tableware product
                  innovation and distribution, hand surgical services
                  management, voice acting services, horse racing partnerships,
                  property management, and fashion development with designs in
                  golf attire and formal wear. He is also very passionate about
                  mediation management &amp; coaching division of his new sports
                  agency.
                </Typography>
                <Typography align="left" paragraph color="textSecondary">
                  These companies are a part of his{" "}
                  <a href="https://www.mjmdglobal.com/">
                    MJMD Global Management Group
                  </a>{" "}
                  and allow consumers to be early experiencers of “New History!”
                </Typography>
                <Button href="https://www.mjmdglobal.com/" color="secondary">
                Visit MJMD Global
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Section>
    </Box>
  )
}

Businesses.propTypes = {}

export default Businesses
