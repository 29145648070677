import React from "react"
import { Box, Container } from "@material-ui/core"

import {
  StyledLogo,
  StyledScrollchor,
  StyledAppBar,
  StyledScrollchorContainer,
} from "./Header.styled"

import LogoSig from "../../images/logo-sig.svg"

const Header = () => {
  return (
    <StyledAppBar m={2}>
      <Container maxWidth="lg">
        <Box sm={12} m={2} display="flex" justifyContent="space-between">
          <Box style={{ position: "relative" }}>
            <StyledLogo src={LogoSig} alt="logo" />
          </Box>
          <StyledScrollchorContainer>
            <StyledScrollchor to="home">Home</StyledScrollchor>
            <StyledScrollchor to="book">Book</StyledScrollchor>
            {/* <StyledScrollchor to="tools">Tools</StyledScrollchor> */}
            <StyledScrollchor to="foundation">Foundation</StyledScrollchor>
            <StyledScrollchor to="businesses">Businesses</StyledScrollchor>
            <StyledScrollchor to="about">About</StyledScrollchor>
            <StyledScrollchor to="contact">Contact</StyledScrollchor>
          </StyledScrollchorContainer>
        </Box>
      </Container>
    </StyledAppBar>
  )
}

Header.propTypes = {}

export default Header
