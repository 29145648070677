import React from "react"
import { Box, Container, Typography } from "@material-ui/core"

import Section from "../Section"
import { StyledAvatar,StyledAvatarContainer } from "./Home.styled"
import Divider from "../Divider"

import ImgAvatar from "../../images/avatar-mj.png"


const Home = () => {
  return (
    <Box id="home">
      <Section variant="primary">
        <Box component="div" py={10} />
      </Section>
      <Section variant="secondary">
        <Divider />
        <Box pb={10}>
          <StyledAvatarContainer>

          <StyledAvatar src={ImgAvatar} alt="dr joyner in black scrubs" />
          </StyledAvatarContainer>
          <Container>
            <Typography variant="h2" align="center" color="white">
              Michael S. Joyner, MD
            </Typography>
            <Typography variant="h4" align="center" color="textPrimary">
              Hand Surgeon, Inventor & Author
            </Typography>
            <Typography align="center" paragraph>
            Dr. Michael Joyner, a Los Angeles native, graduated from Georgetown University School of Medicine in Washington, DC as a United States Army Health Scholarship Recipient. He completed his general surgery internship at Walter Reed Army Medical Center where he served active duty as a captain in the United States Army. Dr. Joyner operates thriving Hand Surgery practices in Florida, California and Nebraska
            </Typography>
          </Container>
        </Box>
      </Section>
      
    </Box>
  )
}

Home.propTypes = {}

export default Home
