import React from "react"
import PropTypes from "prop-types"
import { Box, Button, Container, Grid, Typography } from "@material-ui/core"

import Section from "../Section"
import Divider from "../Divider"

import { StyledFMDLogo } from "./Foundations.styled"

import ImgFmdLogo from "../../images/logo-fmd.svg"

const Foundations = (props) => {
  return (
    <Box>
      <Section variant="white">
        <Divider tc="#002749" bc="#fff" />
        <Box id="foundation" py={10} ref={props.refPos}>
          <Container>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Button href="http://www.fmdgrand.org/">
                  <StyledFMDLogo
                    component="img"
                    src={ImgFmdLogo}
                    alt="joyner holding sign"
                  />
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h2" align="left" color="textPrimary">
                  Foundation
                </Typography>
                <Typography variant="h4" align="left" color="textSecondary">
                  Parental Solutions
                </Typography>
                <Typography align="left" paragraph color="white">
                  {`Born from the aftermath of parental alienation, the Forever My
                  Daddy Grand Foundation was formed. This Foundation fuses split
                  bonds as a result of a broken marriage. Through tested tools
                  and techniques, Dr. Michael J provides parents solutions to
                  raise children in solidarity. Members of this foundation have
                  access to lectures and written books and draw on top
                  techniques to be a better co-parent. Ultimately, the Forever
                  My Daddy Grand Foundation serves to positively prevent
                  parental alienation and encourage fathers to play an active
                  role in their child's life without being blocked by another
                  parent or the flawed legal system.`}
                </Typography>
                <Button href="http://www.fmdgrand.org/">
                  Visit FMDGrand.org
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Section>
    </Box>
  )
}

Foundations.propTypes = {
  refPos: PropTypes.number,
}

export default Foundations
