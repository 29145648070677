import { rem } from "polished"

import { Box, styled } from "@material-ui/core"

const StyledMJGlobalLogo = styled(Box)(({ theme }) => ({
  height: `${rem(500)}`,
  width: `${rem(500)}`,
  padding: `${rem(50)}`,
}))

export { StyledMJGlobalLogo }
