import styled from "styled-components"
import { Avatar } from "@material-ui/core"

const StyledAvatarContainer = styled.div`
@media screen and (max-width: 39.9375em) {
    display:none
}`;


const StyledAvatar = styled(Avatar)({
  height: "500px",
  width: "500px",
  display: "block",
  margin: "0 auto 24px auto",
  position: "absolute",
  top: "0",
  left: "33%",
})

const StyledDividerHome = styled.img`
  width: 100%;
  margin-bottom: 0;
`

export { StyledAvatar, StyledDividerHome ,StyledAvatarContainer}
