/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {
  StylesProvider,
  ThemeProvider as MUIThemeProvider,
} from "@material-ui/core/styles"

import { ThemeProvider } from "styled-components"
import Header from "../Header"
import Footer from "../Footer"

import theme from "../../utils/theme"

const LayoutData = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        menuLinks={data.site.siteMetadata.menuLinks}
      />
      {children}
      <Footer />
    </>
  )
}

const Layout = ({ children }) => (
  <StylesProvider injectFirst>
    <MUIThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <LayoutData>{children}</LayoutData>
      </ThemeProvider>
    </MUIThemeProvider>
  </StylesProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
