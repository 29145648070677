import React from "react"
import PropTypes from "prop-types"
import { Box, Container, Grid, Typography } from "@material-ui/core"

import Section from "../Section"
import Divider from "../Divider"

import ImgAboutJoyner from "../../images/about-joyner-fmd.jpg"

const About = (props) => {
  return (
    <Box>
      <Section variant="secondary">
        <Divider tc="#002749" bc="#76AEA1" />
        <Box id="about" py={10}>
          <Container ref={props.refPos}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  src={ImgAboutJoyner}
                  alt="joyner holding sign"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h2" align="left" color="white">
                  About MJ
                </Typography>
                <Typography variant="h4" align="left" color="primary">
                  Author
                </Typography>
                <Typography align="left" paragraph color="white">
                  Dr. Michael Joyner has had an insatiable appetite throughout
                  his entire life to assist others&#39; lives through surgery.
                </Typography>
                <Typography align="left" paragraph color="white">
                  His thirst for service was first quenched in the military,
                  where he was a Health Scholarship recipient in the US Army.
                  After a general surgical internship at Walter Reed Army
                  Hospital, he served as a General medical officer and Primary
                  brigade surgeon for the US Air Force 311th Training Squadron
                  at the Defense Language Institute in Monterey, CA. He
                  completed his military obligation as a Major in the US Army.
                </Typography>
                <Typography align="left" paragraph color="white">
                  Dr. Michael J undertook undergraduate training at UC Berkeley
                  and Howard University. Following this, he graduated from
                  Georgetown University School of Medicine. Later on, he became
                  proficient at a myriad of professional specialties as a
                  General Surgery with training in trauma, surgical oncology,
                  and reconstructive surgery during his Chief Resident year at
                  Grady Memorial Hospital in Atlanta, Georgia with Morehouse
                  Surgery.
                </Typography>
                <Typography align="left" paragraph color="white">
                  Wherever he has worked, Dr. Michael J has built a remarkable
                  reputation within international hospitals and won prestigious
                  awards as an asset for his service. After completing his Hand
                  &amp; Microsurgery Fellowship at the internationally known
                  Christine M. Kleinert Institute with the University of
                  Louisville, he completed an observational microsurgical
                  fellowship at Chang Gung Memorial Hospital in Taipei, Taiwan.
                </Typography>
                <Typography align="left" paragraph color="white">
                  Outside of clinical life, Dr. Joyner is a talented author, a
                  successful entrepreneur, and the Founder, Chairman, and CEO of
                  MJ MD Global Management Group. Whatever he does, whether
                  it&#39;s in books in the arts, or in his work, this new age
                  renaissance man is always seeking out ways to learn. In
                  addition to this, he loves to serve as a coach to others and
                  share his invaluable wisdom about his career as a guest
                  speaker and mentor.
                </Typography>
                <Typography align="left" paragraph color="white">
                  Through his writing, by being his mentee or listening to him
                  speak, contact him today to learn the tools and techniques
                  that have made Dr. Michael J, a gentleman of many industries.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Section>
    </Box>
  )
}

About.propTypes = {
  refPos: PropTypes.number,
}

export default About
