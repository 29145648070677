import React from "react"
import PropTypes from "prop-types"

import StyledSection from "./StyledSection"

const defaultProps = {
  variant: "primary",
}

const propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(["primary", "secondary", "alternate", "white"]),
}

const Section = ({ children, variant }) => {
  return <StyledSection variant={variant}>{children}</StyledSection>
}

Section.defaultProps = defaultProps

Section.propTypes = propTypes

export default Section
