import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  typography: {
    fontFamily: "'Cabin Condensed', 'Open Sans'",
    fontSize: 16,
    h1: {
      color: "#fff",
    },
    h2: {
      color: "#fff",
    },
    h3: {
      color: "#fff",
    },
    h4: {
      color: "primary",
    },
    h5: {
      color: "primary",
    },
    body1: {
      fontFamily: "Open Sans",
    },
    text: {
      primary: "#ffffff",
      secondary: "primary",
      alternate: "#3E5D70",
    },
  },
 

  palette: {
    secondary: {
      main: "#76AEA1",
    },
    alternate: {
      main: "#3E5D70",
    },
    primary: {
      main: "#002749",
    },
    text: {
      primary: "#002749",
      secondary: "#3E5D70",
   
    },
  },
  shadows: new Array(25),
})

export default theme
