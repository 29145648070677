import React from "react"
import PropTypes from "prop-types"

import { StyledDivider } from "./StyledDivider"

const Divider = ({ tc, mc, bc }) => {
  return (
    <StyledDivider xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 192.95">
      <path fill={bc} d="M0 192.95h512L0 56.78v136.17z" />
      <path fill={tc} d="M0 0l512 137.23V0H0z" />
      <path fill={mc} d="M0 56.78l512 136.17v-55.72L0 0v56.78z" />
    </StyledDivider>
  )
}

Divider.defaultProps = {
  tc: "#002749",
  mc: "#f4f4f5",
  bc: "#76aea1",
}

Divider.propTypes = {
  tc: PropTypes.string,
  mc: PropTypes.string,
  bc: PropTypes.string,
}

export default Divider
