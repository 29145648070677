import React from "react"
import PropTypes from "prop-types"
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Paper,
} from "@material-ui/core"

import Section from "../Section"
import Divider from "../Divider"

import LogoFmdBookSeries from "../../images/logo-fmd-book-series.png"
import ImgBook from "../../images/book.png"
import ImgBookDontGetScrewed from "../../images/book-dont-get-screwed.jpg"
import ImgFinley from "../../images/finley.png"
import ImgFinleyBook from "../../images/finley-book.jpg"
import ImgColoringBook from "../../images/finley-coloring-book.jpg"
import ImgColoringBook2 from "../../images/finley-coloring-book-2.jpg"
import ImgColoringBookChinese from "../../images/finley-book-chinese.jpg"
import ImgColoringBookSpanish from "../../images/finley-book-spanish.jpg"
import ImgFinleyBookYellowstone from "../../images/finley-book-yellowstone.jpg"

const Book = (props) => {
  return (
    <Box>
      <Section variant="white">
        <Divider tc="#76AEA1" bc="#fff" />
        <Box id="book" py={10}>
          <Container ref={props.refPos}>
            <Box py={10}>
              <Box display="flex" justifyContent="center">
                <Box
                  component="img"
                  src={LogoFmdBookSeries}
                  style={{ width: "300px" }}
                />
              </Box>
            </Box>
            <Box py={10}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box component="img" src={ImgBook} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography variant="h2" align="left" color="textPrimary">
                      Forever My Daddy:Denied
                    </Typography>
                    <Typography variant="h4" align="left" color="textSecondary">
                      Blocked from my children
                    </Typography>
                    <Typography align="left" paragraph>
                      Dr. Michael J. shares the concept of Forever My Daddy
                      (FMD) that emerged over twenty years ago. FMD is the voice
                      for fathers who are unfairly pushed away from their
                      children, and blocked from developing a deep and true
                      relationship, whose desire to be a loving presence for
                      their children is thwarted by subtle actions of the prior
                      spouses and the biases of court systems that actually
                      serve to increase fatherlessness. He shares ideas and
                      lessons in a fascinating way that could assist parents,
                      children, teachers, girlfriends, spouses and others on
                      ways to avoid parental alienation while highlighting
                      co-parenting.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box py={10}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box component="img" src={ImgBookDontGetScrewed} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography variant="h2" align="left" color="textPrimary">
                      {`Don't get screwed!`}
                    </Typography>
                    <Typography variant="h4" align="left" color="textSecondary">
                      After All of Your Hard Work, Avoid Exploitation in a
                      Divorce
                    </Typography>
                    <Typography align="left" paragraph>
                      Divorce appears more common for high-value individuals
                      than the average population, especially since there are
                      higher stakes involved. In this situation, they turn into
                      targets for their ex-spouse, the lawyers on both sides,
                      and the courts to exploit them as much as possible. While
                      divorce can happen to anyone, the possibility is
                      especially high for these individuals. And if they haven’t
                      taken any asset protection measures, divorce can incur a
                      greater loss than usual.
                    </Typography>
                    <Typography align="left" paragraph>
                      There’s one reason for all of that: money.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box py={10}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography variant="h2" align="left" color="textPrimary">
                      FMD Book Series Children’s Books
                    </Typography>
                    <Typography variant="h4" align="left" color="textSecondary">
                      Meet Finley Miles Darby
                    </Typography>
                    <Typography align="left" paragraph>
                      Every time Finley visits his father, he would learn
                      something new. Finley is always proud of what he learns.
                      In this book, Finley explains to his best friends, his two
                      dogs Zen and Buddha how he learned to use Touchless
                      Chopsticks. Finley Miles Darby and Touchless Chopsticks
                      Coloring Book
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box display="flex" justifyContent="center">
                    <Box
                      component="img"
                      src={ImgFinley}
                      style={{ width: "450px" }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box py={10}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box component="img" src={ImgFinleyBook} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography variant="h4" align="left" color="textSecondary">
                      Finley Miles Darby and Touchless Chopsticks
                    </Typography>

                    <Typography align="left" paragraph>
                      Finley visits his Father and he learns how to use
                      Touchless Chopsticks. Finley shares with Zen and Buddha
                      how he has learned to used Touchless Chopsticks.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* Yellowstone Edition */}
            <Box py={10}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box component="img" src={ImgFinleyBookYellowstone} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography variant="h4" align="left" color="textSecondary">
                      {`Finley Miles Darby Meets the Bisons at Yellowstone`}
                    </Typography>
                    <Typography
                      align="left"
                      paragraph
                    >{`Finley loves getting to know his Father. His parents are in a co-parenting relationship. He did not spend much time with his Father before he decided that he wanted to get to know him. In the book, “Finley Miles Darby Meets the Bisons at Yellowstone”, Finley shares his experiences with his best friends, his dogs Zen and Buddha.`}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* Chinese Edition */}
            <Box py={10}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box component="img" src={ImgColoringBookChinese} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography variant="h4" align="left" color="textSecondary">
                      {`“芬利·迈尔斯·达尔比” 童书系列之 《不沾桌筷子》: Finley Miles Darby and Touchless Chopsticks (Chinese Edition) (Forever My Daddy Book Series) (Traditional Chinese Edition) Kindle Edition`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* Spanish Editin */}
            <Box py={10}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box component="img" src={ImgColoringBookSpanish} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography variant="h4" align="left" color="textSecondary">
                      {`Finley Miles Darby y Los Palillos Sin Contacto (Spanish Edition)`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box py={10}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box mb={2}>
                    <Box component="img" src={ImgColoringBook} />
                  </Box>
                  <Box>
                    <Box component="img" src={ImgColoringBook2} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography variant="h4" align="left" color="textSecondary">
                      Finley Miles Darby and Touchless Chopsticks Coloring Books
                    </Typography>

                    <Typography align="left" paragraph>
                      Have fun coloring Finley and his best friends! This is a
                      great book to read to your children while they color with
                      you!
                    </Typography>
                    <Typography align="left" paragraph>
                      Coloring books are great tools for adults and children.
                      Coloring books help relax the mind and can help improve
                      your brain’s ability to function.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box py={10}>
              <Grid container>
                <Grid item xs={12}>
                  <Paper variant="outlined" elevation={24}>
                    <Box p={5}>
                      <Box mb={5}>
                        <Typography
                          variant="h4"
                          align="center"
                          color="textSecondary"
                        >
                          {` Visit Amazon and purchase one of Dr. J's books!`}
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="center">
                        <Button
                          variant="contained"
                          size="large"
                          href="https://www.amazon.com/~/e/B00E7791AC"
                          target="_blank"
                          rel="noopener"
                        >
                          Purchase Book
                        </Button>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Section>
    </Box>
  )
}

Book.propTypes = {
  refPos: PropTypes.node,
}

export default Book
