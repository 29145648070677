
import { rem } from "polished"
import styled from 'styled-components'
import Scrollchor from "react-scrollchor"
import {AppBar, Box } from "@material-ui/core"

const StyledScrollchorContainer = styled(Box)`
display: flex;
justify-content: space-between;
@media screen and (max-width: 39.9375em) {
    display: none;
}
`

const StyledLogo = styled.img`
  width: ${rem(270)};
  margin: 0;

  filter: drop-shadow(0px 5px 0px rgba(0, 0, 0, 0.2));
  top: 0;
  @media screen and (max-width: 39.9375em) {
    margin: 0 auto;
    width: 100%
}
`

const StyledScrollchor = styled(Scrollchor)`
  margin: ${rem(10)} ${rem(20)} ${rem(10)} ${rem(20)};
  font-family: "Cabin Condensed";
  font-size: ${rem(24)};
  color: #ccc;
  text-decoration: none;
`

const StyledAppBar = styled(AppBar)`
  background-color: transparent;
`

export { StyledLogo, StyledScrollchor, StyledAppBar,StyledScrollchorContainer }
