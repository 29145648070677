import React from "react"
import styled from "styled-components"

import { Box } from "@material-ui/core"

const StyledSection = styled(({ ...rest }) => <Box {...rest} />)`
  background-color: ${({ theme, variant }) => {
    switch (variant) {
      case "secondary":
        return theme.palette.secondary.main
      case "alternate":
        return theme.palette.alternate.main
      case "white":
        return theme.palette.common.white
      default:
        return theme.palette.primary.main
    }
  }};
  position: relative;
`

export default StyledSection
